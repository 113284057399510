var data = {
    "0": {
        "param_order": [
            "0", 
            "1", 
            "2", 
            "3", 
            "4", 
            "5", 
            "6", 
            "7", 
            "23", 
            "24", 
            "25", 
            "30", 
            "31"
        ], 
        "parameters": {
            "0": {
                "name": {
                    "label": "durum", 
                    "translation": {
                        "en": "status", 
                        "tr": "durum"
                    }, 
                    "value": "0"
                }, 
                "options": [
                    {
                        "label": "pasif", 
                        "translation": {
                            "en": "passive", 
                            "tr": "pasif"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "aktif", 
                        "translation": {
                            "en": "active", 
                            "tr": "aktif"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "1": {
                "name": {
                    "label": "menüde göster", 
                    "translation": {
                        "en": "show in menu", 
                        "tr": "menüde göster"
                    }, 
                    "value": "1"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "2": {
                "name": {
                    "label": "menü başlığı", 
                    "translation": {
                        "en": "menu caption", 
                        "tr": "menü başlığı"
                    }, 
                    "value": "2"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "23": {
                "name": {
                    "label": "website birimi no", 
                    "translation": {
                        "en": "website unit no", 
                        "tr": "website birimi no"
                    }, 
                    "value": "23"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "integer"
            }, 
            "24": {
                "name": {
                    "label": "üst website birimi no", 
                    "translation": {
                        "en": "parent website unit no", 
                        "tr": "üst website birimi no"
                    }, 
                    "value": "24"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "integer"
            }, 
            "25": {
                "name": {
                    "label": "yönlendirme adı", 
                    "translation": {
                        "en": "route name", 
                        "tr": "yönlendirme adı"
                    }, 
                    "value": "25"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "3": {
                "name": {
                    "label": "tıklama tipi", 
                    "translation": {
                        "en": "click type", 
                        "tr": "tıklama tipi"
                    }, 
                    "value": "3"
                }, 
                "options": [
                    {
                        "label": "uygulama sayfası", 
                        "translation": {
                            "en": "application page", 
                            "tr": "uygulama sayfası"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "belirtilen link", 
                        "translation": {
                            "en": "defined url", 
                            "tr": "belirtilen link"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "30": {
                "name": {
                    "label": "yetki durumu", 
                    "translation": {
                        "en": "authorization status", 
                        "tr": "yetki durumu"
                    }, 
                    "value": "30"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "31": {
                "name": {
                    "label": "yetki kodu", 
                    "translation": {
                        "en": "authorization code", 
                        "tr": "yetki kodu"
                    }, 
                    "value": "31"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "4": {
                "name": {
                    "label": "yönlendirme yolu", 
                    "translation": {
                        "en": "route path", 
                        "tr": "yönlendirme yolu"
                    }, 
                    "value": "4"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "5": {
                "name": {
                    "label": "sayfa üst başlık", 
                    "translation": {
                        "en": "page top caption", 
                        "tr": "sayfa üst başlık"
                    }, 
                    "value": "5"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "6": {
                "name": {
                    "label": "anasayfa mı ?", 
                    "translation": {
                        "en": "is dashboard", 
                        "tr": "anasayfa mı ?"
                    }, 
                    "value": "6"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "7": {
                "name": {
                    "label": "sayfa içeriği", 
                    "translation": {
                        "en": "page info", 
                        "tr": "sayfa içeriği"
                    }, 
                    "value": "7"
                }, 
                "param_order": [
                    "8", 
                    "27", 
                    "9"
                ], 
                "parameters": {
                    "27": {
                        "name": {
                            "label": "komponent tipi", 
                            "translation": {
                                "en": "component type", 
                                "tr": "komponent tipi"
                            }, 
                            "value": "27"
                        }, 
                        "options": [
                            {
                                "label": "genel", 
                                "translation": {
                                    "en": "general", 
                                    "tr": "genel"
                                }, 
                                "value": "0"
                            }, 
                            {
                                "label": "slider 1", 
                                "translation": {
                                    "en": "slider 1", 
                                    "tr": "slider 1"
                                }, 
                                "value": "1"
                            }, 
                            {
                                "label": "4 kolon - yazı görünümü - link yazı üzerinde", 
                                "translation": {
                                    "en": "4 column - text view - link on text", 
                                    "tr": "4 kolon - yazı görünümü - link yazı üzerinde"
                                }, 
                                "value": "2"
                            }, 
                            {
                                "label": "4 kolon - yazı görünümü - link detaylar için tıklayınız", 
                                "translation": {
                                    "en": "4 column - text view - link click for details", 
                                    "tr": "4 kolon - yazı görünümü - link detaylar için tıklayınız"
                                }, 
                                "value": "3"
                            }, 
                            {
                                "label": "2 kolon - sol yazı görünümü - sağ resim", 
                                "translation": {
                                    "en": "2 column - left text view - right image", 
                                    "tr": "2 kolon - sol yazı görünümü - sağ resim"
                                }, 
                                "value": "4"
                            }, 
                            {
                                "label": "2 kolon - sağ yazı görünümü - sol resim", 
                                "translation": {
                                    "en": "2 column - right text view - left image", 
                                    "tr": "2 kolon - sağ yazı görünümü - sol resim"
                                }, 
                                "value": "5"
                            }, 
                            {
                                "label": "1 kolon - sol yazı görünümü - arka plan resim", 
                                "translation": {
                                    "en": "1 column - left text view - background image", 
                                    "tr": "1 kolon - sol yazı görünümü - arka plan resim"
                                }, 
                                "value": "6"
                            }, 
                            {
                                "label": "1 kolon - sağ yazı görünümü - arka plan resim", 
                                "translation": {
                                    "en": "1 column - right text view - background image", 
                                    "tr": "1 kolon - sağ yazı görünümü - arka plan resim"
                                }, 
                                "value": "7"
                            }, 
                            {
                                "label": "1 kolon - merkez yazı görünümü - resim yok", 
                                "translation": {
                                    "en": "1 column - center text view - no image", 
                                    "tr": "1 kolon - merkez yazı görünümü - resim yok"
                                }, 
                                "value": "8"
                            }, 
                            {
                                "label": "2 kolon - sağ yazı görünümü - sol resim ikon", 
                                "translation": {
                                    "en": "2 column - right text view - left image icon", 
                                    "tr": "2 kolon - sağ yazı görünümü - sol resim ikon"
                                }, 
                                "value": "9"
                            }, 
                            {
                                "label": "3 kolon - alt yazı görünümü - başlığın solunda resim ikon", 
                                "translation": {
                                    "en": "3 column - below text view - left image icon of caption", 
                                    "tr": "3 kolon - alt yazı görünümü - başlığın solunda resim ikon"
                                }, 
                                "value": "10"
                            }, 
                            {
                                "label": "3 kolon - alt yazı görünümü - başlığın üstünde resim", 
                                "translation": {
                                    "en": "3 column - below text view - top image of caption", 
                                    "tr": "3 kolon - alt yazı görünümü - başlığın üstünde resim"
                                }, 
                                "value": "11"
                            }, 
                            {
                                "label": "1 kolon - merkez yazı görünümü - arka plan tam genişlikte resim - detaylar için tıklayınız linki yazı formatında", 
                                "translation": {
                                    "en": "1 column - center text view - background fullwidth image - link click for details as text", 
                                    "tr": "1 kolon - merkez yazı görünümü - arka plan tam genişlikte resim - detaylar için tıklayınız linki yazı formatında"
                                }, 
                                "value": "12"
                            }, 
                            {
                                "label": "1 kolon - merkez yazı görünümü - arka plan tam genişlikte resim - detaylar için tıklayınız linki buton formatında", 
                                "translation": {
                                    "en": "1 column - center text view - background fullwidth image - link click for details as button", 
                                    "tr": "1 kolon - merkez yazı görünümü - arka plan tam genişlikte resim - detaylar için tıklayınız linki buton formatında"
                                }, 
                                "value": "13"
                            }, 
                            {
                                "label": "2 kolon resim kolonu küçük - sol yazı görünümü - sağ resim", 
                                "translation": {
                                    "en": "2 column image column smaller - left text view - right image", 
                                    "tr": "2 kolon resim kolonu küçük - sol yazı görünümü - sağ resim"
                                }, 
                                "value": "14"
                            }, 
                            {
                                "label": "2 kolon resim kolonu küçük - sağ yazı görünümü - sol resim", 
                                "translation": {
                                    "en": "2 column image column smaller - right text view - left image", 
                                    "tr": "2 kolon resim kolonu küçük - sağ yazı görünümü - sol resim"
                                }, 
                                "value": "15"
                            }
                        ], 
                        "prefix": "", 
                        "show": "yes", 
                        "type": "select"
                    }, 
                    "8": {
                        "name": {
                            "label": "sayfa iç başlığı", 
                            "translation": {
                                "en": "page info caption", 
                                "tr": "sayfa iç başlığı"
                            }, 
                            "value": "8"
                        }, 
                        "prefix": "", 
                        "show": "yes", 
                        "type": "text"
                    }, 
                    "9": {
                        "name": {
                            "label": "içerik detayı", 
                            "translation": {
                                "en": "page info detail", 
                                "tr": "içerik detayı"
                            }, 
                            "value": "9"
                        }, 
                        "param_order": [
                            "10", 
                            "26", 
                            "11", 
                            "12", 
                            "13", 
                            "14", 
                            "15", 
                            "16", 
                            "17", 
                            "18", 
                            "19", 
                            "20", 
                            "21", 
                            "28", 
                            "32", 
                            "29", 
                            "22"
                        ], 
                        "parameters": {
                            "10": {
                                "name": {
                                    "label": "detay başlığı", 
                                    "translation": {
                                        "en": "page info detail caption", 
                                        "tr": "detay başlığı"
                                    }, 
                                    "value": "10"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "11": {
                                "name": {
                                    "label": "detay buton adı", 
                                    "translation": {
                                        "en": "button name", 
                                        "tr": "detay buton adı"
                                    }, 
                                    "value": "11"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "12": {
                                "name": {
                                    "label": "detay kod", 
                                    "translation": {
                                        "en": "code", 
                                        "tr": "detay kod"
                                    }, 
                                    "value": "12"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "13": {
                                "name": {
                                    "label": "detay link", 
                                    "translation": {
                                        "en": "link", 
                                        "tr": "detay link"
                                    }, 
                                    "value": "13"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "14": {
                                "name": {
                                    "label": "detay metod adı", 
                                    "translation": {
                                        "en": "method name", 
                                        "tr": "detay metod adı"
                                    }, 
                                    "value": "14"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "15": {
                                "name": {
                                    "label": "detay tetikleme noktası", 
                                    "translation": {
                                        "en": "trigger point", 
                                        "tr": "detay tetikleme noktası"
                                    }, 
                                    "value": "15"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "16": {
                                "name": {
                                    "label": "detay resim adı", 
                                    "translation": {
                                        "en": "image name", 
                                        "tr": "detay resim adı"
                                    }, 
                                    "value": "16"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "17": {
                                "name": {
                                    "label": "detay mail", 
                                    "translation": {
                                        "en": "mail", 
                                        "tr": "detay mail"
                                    }, 
                                    "value": "17"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "18": {
                                "name": {
                                    "label": "detay iframe", 
                                    "translation": {
                                        "en": "iframe", 
                                        "tr": "detay iframe"
                                    }, 
                                    "value": "18"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "19": {
                                "name": {
                                    "label": "detay resim linki", 
                                    "translation": {
                                        "en": "img link", 
                                        "tr": "detay resim linki"
                                    }, 
                                    "value": "19"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "20": {
                                "name": {
                                    "label": "detay makine öğrenmesi id", 
                                    "translation": {
                                        "en": "machine learning id", 
                                        "tr": "detay makine öğrenmesi id"
                                    }, 
                                    "value": "20"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "21": {
                                "name": {
                                    "label": "wanalyzer proje id", 
                                    "translation": {
                                        "en": "wanalyzer project id", 
                                        "tr": "wanalyzer proje id"
                                    }, 
                                    "value": "21"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "22": {
                                "name": {
                                    "label": "detay resim base64", 
                                    "translation": {
                                        "en": "image base64", 
                                        "tr": "detay resim base64"
                                    }, 
                                    "value": "22"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "26": {
                                "name": {
                                    "label": "detay açıklaması", 
                                    "translation": {
                                        "en": "page info detail note", 
                                        "tr": "detay açıklaması"
                                    }, 
                                    "value": "26"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "textarea"
                            }, 
                            "28": {
                                "name": {
                                    "label": "wanalyzer proje kullanım tipi", 
                                    "translation": {
                                        "en": "wanalyzer project usage type", 
                                        "tr": "wanalyzer proje kullanım tipi"
                                    }, 
                                    "value": "28"
                                }, 
                                "options": [
                                    {
                                        "label": "kolon girdileriyle analiz çıktısı alma", 
                                        "translation": {
                                            "en": "getting analysis output with column inputs", 
                                            "tr": "kolon girdileriyle analiz çıktısı alma"
                                        }, 
                                        "value": "0"
                                    }, 
                                    {
                                        "label": "wanalyzer istatistik görüntüle", 
                                        "translation": {
                                            "en": "wanalyzer stats view", 
                                            "tr": "wanalyzer istatistik görüntüle"
                                        }, 
                                        "value": "1"
                                    }, 
                                    {
                                        "label": "wanalyzer dataset filtrele", 
                                        "translation": {
                                            "en": "wanalyzer dataset filter", 
                                            "tr": "wanalyzer dataset filtrele"
                                        }, 
                                        "value": "2"
                                    }, 
                                    {
                                        "label": "wanalyzer dataset istatistik sorgula", 
                                        "translation": {
                                            "en": "wanalyzer dataset stats query", 
                                            "tr": "wanalyzer dataset istatistik sorgula"
                                        }, 
                                        "value": "3"
                                    }, 
                                    {
                                        "label": "wanalyzer makine öğrenmesi sorgula", 
                                        "translation": {
                                            "en": "wanalyzer machine learning query", 
                                            "tr": "wanalyzer makine öğrenmesi sorgula"
                                        }, 
                                        "value": "4"
                                    }, 
                                    {
                                        "label": "wanalyzer sorgu oluşturma", 
                                        "translation": {
                                            "en": "wanalyzer create query", 
                                            "tr": "wanalyzer sorgu oluşturma"
                                        }, 
                                        "value": "5"
                                    }
                                ], 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "select"
                            }, 
                            "29": {
                                "name": {
                                    "label": "kolon girdileriyle analiz çıktısı alma id tip", 
                                    "translation": {
                                        "en": "getting analysis output with column inputs id type", 
                                        "tr": "kolon girdileriyle analiz çıktısı alma id tip"
                                    }, 
                                    "value": "29"
                                }, 
                                "options": [
                                    {
                                        "label": "yok", 
                                        "translation": {
                                            "en": "no", 
                                            "tr": "yok"
                                        }, 
                                        "value": "0"
                                    }, 
                                    {
                                        "label": "client id bazlı", 
                                        "translation": {
                                            "en": "client id based", 
                                            "tr": "client id bazlı"
                                        }, 
                                        "value": "1"
                                    }, 
                                    {
                                        "label": "wdmr id bazlı", 
                                        "translation": {
                                            "en": "based on wdmr id", 
                                            "tr": "wdmr id bazlı"
                                        }, 
                                        "value": "2"
                                    }, 
                                    {
                                        "label": "client id veya wdmr id bazlı", 
                                        "translation": {
                                            "en": "based on client id or wdmr id", 
                                            "tr": "client id veya wdmr id bazlı"
                                        }, 
                                        "value": "3"
                                    }
                                ], 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "select"
                            }, 
                            "32": {
                                "name": {
                                    "label": "wanalyzer istatistik id", 
                                    "translation": {
                                        "en": "wanalyzer stats id", 
                                        "tr": "wanalyzer istatistik id"
                                    }, 
                                    "value": "32"
                                }, 
                                "prefix": "", 
                                "show": "yes", 
                                "type": "text"
                            }
                        }, 
                        "prefix": "", 
                        "show": "yes", 
                        "type": "list_parameter"
                    }
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "list_parameter"
            }
        }
    }, 
    "about": [], 
    "bmi_gfr": "no", 
    "calculate_eval": "no", 
    "checked_on_open": "no", 
    "date_ability": "yes", 
    "date_ability_limit": "none", 
    "date_ability_type": "date", 
    "date_ability_user_selection": "no", 
    "document_upload": "no", 
    "ending_date_ability": "yes", 
    "ending_date_ability_limit": "none", 
    "ending_date_ability_type": "date", 
    "ending_date_ability_user_selection": "no", 
    "favorite_show_mode": "yes", 
    "language": {
        "label": "Türkçe", 
        "value": "tr"
    }, 
    "model": "wisdom_data", 
    "module": [], 
    "name": {
        "label": "wdm_name !!!", 
        "translation": {
            "en": "frontend website unit", 
            "tr": "frontend website birim"
        }, 
        "value": "ly + Created Layer Id !!!"
    }, 
    "owner": {
        "label": "katman", 
        "value": "layer"
    }, 
    "owner_type": "layer", 
    "param_group": {
        "param_order": [
            "0"
        ], 
        "parameters": {
            "0": {
                "label": "Param", 
                "value": "0"
            }
        }
    }, 
    "parent": "ly + Created Layer Id", 
    "record_count": "many_times", 
    "status": "published", 
    "style": {
        "caption": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 12
        }, 
        "value": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 10
        }
    }, 
    "value": "ly + Created Layer Id", 
    "version": "1.0"
};
export { data }
